<template>
  <nav class="navbar navbar-expand navbar-light bg-light">
    <div class="container">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li v-if="loginInfo.isAdmin" class="nav-item">
          <a class="nav-link" :class="{ active: file == 'manager' || file == 'setting' }" href="/manager-1.html">담당자 관리</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" :class="{ active: file == 'coupon' }" href="/coupon-1.html">쿠폰 관리</a>
        </li>
        <li v-if="loginInfo.isGuest" class="nav-item">
          <a class="nav-link" :class="{ active: file == 'url' }" href="/url.html">초대 링크 관리</a>
        </li>
        <li v-if="!loginInfo.isAdmin" class="nav-item">
          <a class="nav-link" :class="{ active: file == 'setting' }" href="/setting.html">기타 설정</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" @click="logoutClick" href="#">로그아웃</a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>

import { ref } from 'vue';

export default {
  props: {
    loginInfo: Object
  },
  setup() {
    const path = new RegExp('^/([a-zA-Z]+)(\\-[0-9a-z]+)?\\.html').exec(location.pathname);
    if(!path) return;

    let file = ref(path[1]);

    const logoutClick = async () => {
      await backend({ action: 'logout' });
      location.href = '/';
    };

    return { file, logoutClick };
  }
}
</script>
