<template>
  <div>
    <Menu :loginInfo="loginInfo" />
    <h2 class="my-4">쿠폰 관리</h2>
    <h3 class="ms-3 mt-4">쿠폰 목록</h3>
    <div class="row">
      <div class="col text-end">
        Count: {{ couponListPager.count }}
        Page: {{ couponListPager.page }} / {{ couponListPager.pageMax }} 
      </div>
    </div>
    <div v-if="msg" class="row" :class="{ blink: !!msg }">
      <div class="col">
        <div class="alert alert-info">{{ msg }}
          <button class="btn bg-transparent py-0" @click="msgCloseClick" style="font-size: 2rem; position: absolute; right: 0px; top: 0px;">
            <i class="bi bi-x"></i>
          </button>
      </div>
      </div>
    </div>
    <table class="table table-striped align-middle text-center">
      <thead>
        <tr>
          <th scope="col"><input type="checkbox" class="form-check-input" @change="selectAllChange" v-model="select.all" /></th>
          <th scope="col">No.</th>
          <th scope="col">코드</th>
          <th scope="col">기간</th>
          <th scope="col">유료</th>
          <th scope="col">발급</th>
          <th scope="col">사용일자</th>
          <th scope="col">사용자</th>
          <th v-if="loginInfo.isAdmin" scope="col">담당자</th>
          <th scope="col">쿠폰 이름</th>
          <th scope="col" v-if="loginInfo.isAdmin">관리자 메모</th>
          <th scope="col"><span v-if="loginInfo.isAdmin">담당자 </span>메모</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item of couponList" :key="item.num" :class="{ marked: markedCoupons.find(code => code == item.code) }">
          <td><input type="checkbox" class="form-check-input" v-model="select.coupons" :value="item.code" /></td>
          <td>{{ item.num }}</td>
          <td>{{ item.code }}</td>
          <td>{{ item.timeFormat }}</td>
          <td>{{ item.is_buy ? '유료' : '무료' }}</td>
          <td>{{ item.publishFormat }}</td>
          <td>{{ item.usedFormat || '미사용' }}</td>
          <td>{{ item.name || '미사용' }}</td>
          <td v-if="loginInfo.isAdmin">{{ item.manager }}</td>
          <td>{{ item.text }}</td>
          <td v-if="loginInfo.isAdmin">{{ item.adminMemo }}</td>
          <td>{{ item.managerMemo }}</td>
        </tr>
        <tr v-if="!couponList.length">
          <td :colspan="loginInfo.isAdmin ? 12 : 10">자료없음</td>
        </tr>
      </tbody>
    </table>
    <div class="row">
      <div class="col text-center" v-if="!search.isSearch">
        <a v-for="(pageBtn, idx) in couponListPager.btns" :key="idx" class="btn mx-1" :class="{ 'btn-primary': pageBtn == couponListPager.page, 'btn-secondary': pageBtn != couponListPager.page }" :href="'coupon-' + pageBtn + '.html'">{{ pageBtn }}</a>
      </div>
      <div class="col text-center" v-if="search.isSearch">
        <button v-for="(pageBtn, idx) in couponListPager.btns" :key="idx" class="btn mx-1" :class="{ 'btn-primary': pageBtn == couponListPager.page, 'btn-secondary': pageBtn != couponListPager.page }" @click="searchRequest(pageBtn)">{{ pageBtn }}</button>
      </div>
    </div>
    <div v-if="!search.show" class="row mt-3">
      <div class="col text-center">
        <button class="btn btn-primary" @click="searchShowClick">쿠폰 검색</button>
      </div>
    </div>
    <div v-if="search.show">
      <hr class="my-5" />
      <h4 class="ms-3">쿠폰 검색</h4>
      <div class="card" style="max-width: 900px; margin: auto;">
        <div class="card-body">
          <button class="btn bg-transparent py-0" @click="searchShowClick" style="font-size: 2rem; position: absolute; right: 0px; top: 0px;">
            <i class="bi bi-x"></i>
          </button>
          <div class="row mt-2">
            <div class="col-5 pe-5">
              <label class="text-secondary">쿠폰 코드</label>
              <input class="form-control" v-model="search.coupon.code" />
            </div>
            <div class="col">
              <label class="text-secondary">쿠폰 기간</label>
              <div class="row row-cols-auto align-items-center">
                <div class="col pe-1">
                  <input class="form-control" style="width: 60px" v-model="search.time.start" />
                </div>
                <label class="px-0">일 이상</label>
                <label class="px-0">, </label>
                <div class="col pe-1">
                  <input class="form-control" style="width: 60px" v-model="search.time.end" />
                </div>
                <label class="px-0">일 이하</label>
                <button v-for="time in [10, 30, 90]" :key="time" class="btn btn-secondary ms-2" @click="search.time.start = search.time.end = time">{{time}}일</button>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-5"></div>
            <div class="col">
              <label class="text-secondary">발급일자</label>
              <div class="row row-cols-auto align-items-center">
                <div class="row row-cols-auto align-items-center pe-1">
                  <Datepicker class="form-control" style="width: 132px;" :locale="locale" v-model="search.publish.start" />
                  <button class="btn bg-transparent px-1" style="margin-left: -40px; z-index: 5;" @click="delete search.publish.start" >
                    <i class="bi bi-x"></i>
                  </button>
                </div>
                <label class="px-0">~</label>
                <div class="row row-cols-auto align-items-center ps-1">
                  <Datepicker class="form-control" style="width: 132px;" :locale="locale" v-model="search.publish.end" />
                  <button class="btn bg-transparent px-1" style="margin-left: -40px; z-index: 5;" @click="delete search.publish.end">
                    <i class="bi bi-x"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-5">
              <label class="text-secondary">사용여부</label>
              <div class="mt-2">
                <input type="radio" class="form-check-input me-1" id="searchUseAll" v-model="search.used.state" value="all" /><label class="form-check-label" for="searchUseAll">모두</label>
                <input type="radio" class="form-check-input ms-3 me-1" id="searchUseYes" v-model="search.used.state" value="yes" /><label class="form-check-label" for="searchUseYes">사용된 쿠폰</label>
                <input type="radio" class="form-check-input ms-3 me-1" id="searchUseNo" v-model="search.used.state" value="no" /><label class="form-check-label" for="searchUseNo">미사용 쿠폰</label>
              </div>
            </div>
            <div class="col">
              <label class="text-secondary">사용일자</label>
              <div class="row row-cols-auto align-items-center">
                <div class="row row-cols-auto align-items-center pe-1">
                  <Datepicker class="form-control" style="width: 132px;" :locale="locale" v-model="search.used.start" />
                  <button class="btn bg-transparent px-1" style="margin-left: -40px; z-index: 5;" @click="delete search.used.start">
                    <i class="bi bi-x"></i>
                  </button>
                </div>
                <label class="px-0">~</label>
                <div class="row row-cols-auto align-items-center ps-1">
                  <Datepicker class="form-control" style="width: 132px;" :locale="locale" v-model="search.used.end" />
                  <button class="btn bg-transparent px-1" style="margin-left: -40px; z-index: 5;" @click="delete search.used.end">
                    <i class="bi bi-x"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4" v-if="loginInfo.isAdmin">
            <div class="col-5 pe-5">
              <label class="text-secondary">담당자 검색</label>
              <div class="input-group">
              <input class="form-control" v-model="searchManagers" />
              <button class="btn btn-secondary" @click="searchManagerClick">검색 <i class="bi bi-arrow-right-circle"></i></button>
              </div>
            </div>
            <div class="col">
              <label class="text-secondary">담당자 선택</label>
              <div class="row row-cols-auto align-items-center">
                <div class="dropdown d-inline-block">
                  <button class="btn btn-secondary dropdown-toggle" type="button" id="serachManager" data-bs-toggle="dropdown" aria-expanded="false">
                    {{ search.manager.select || '전체 담당자' }}
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="serachManager">
                    <li class="dropdown-item" @click="search.manager.select = null">전체 담당자</li>
                    <li v-for="manager in managersSearch" :key="manager.id" class="dropdown-item" @click="search.manager.select = manager.id">{{manager.id}}<span v-if="manager.memo"> - {{manager.memo}}</span></li>
                  </ul>
                </div>
                <span v-if="isManagersSearch" class="text ps-0">{{ managersSearch.length }}건으로 필터링됨</span>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-5 pe-5">
              <label class="text-secondary">쿠폰 이름</label>
              <input class="form-control" v-model="search.coupon.text" />
            </div>
            <div class="col-7" v-if="loginInfo.isAdmin">
              <label class="text-secondary">관리자 메모</label>
              <input class="form-control" v-model="search.coupon.adminMemo" />
            </div>
            <div class="col-5" v-if="loginInfo.isAdmin"></div>
            <div class="col-7" :class="{ 'mt-4': loginInfo.isAdmin }" >
              <label class="text-secondary"><span v-if="loginInfo.isAdmin">담당자 </span>메모</label>
              <input class="form-control" v-model="search.coupon.managerMemo" />
            </div>
          </div>
          <div class="row mt-3 text-center">
            <div class="col">
              <button class="btn btn-primary" @click="searchRequest(1)">쿠폰 검색</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="my-5" />
    <h4 v-if="select.coupons.length == 0" class="ms-3">선택 쿠폰 일괄 처리</h4>
    <h4 v-if="select.coupons.length != 0" class="ms-3">선택한 {{ select.coupons.length }}개 쿠폰 일괄 처리</h4>
    <div v-if="loginInfo.isAdmin" class="row text-center">
      <div class="col">
        <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modal" :disabled="!select.coupons.length">일괄삭제</button>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col">
        <label class="text-secondary">쿠폰 이름 일괄적용</label>
        <input class="form-control" v-model="batch.text" />
        <small>쿠폰을 사용하는 사람에게 보여지는 이름입니다.</small>
      </div>
    </div>
    <div class="row text-center mt-2">
      <div class="col">
        <button class="btn btn-primary" @click="batchTextRequest" :disabled="!select.coupons.length">쿠폰이름 일괄수정</button>
      </div>
    </div>
    <div v-if="loginInfo.isAdmin" class="row mt-2">
      <div class="col">
        <label class="text-secondary">관리자 메모 일괄적용</label>
        <input class="form-control" v-model="batch.adminMemo" />
      </div>
    </div>
    <div v-if="loginInfo.isAdmin" class="row text-center mt-2">
      <div class="col">
        <button class="btn btn-primary" @click="batchAdminMemoRequest" :disabled="!select.coupons.length">관리자 메모 일괄수정</button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <label class="text-secondary"><span v-if="loginInfo.isAdmin">담당자 </span>메모 일괄적용</label>
        <input class="form-control" v-model="batch.managerMemo" />
        <small>담당자만 볼 수 있는 메모입니다.</small>
      </div>
    </div>
    <div v-if="loginInfo.isAdmin" class="row text-center mt-2">
      <div class="col">
        <button class="btn btn-primary" @click="batchManagerMemoRequest" :disabled="!select.coupons.length"><span v-if="loginInfo.isAdmin">담당자 </span>메모 일괄수정</button>
      </div>
    </div>
    <hr v-if="loginInfo.isAdmin" class="mb-5" />
    <h4 v-if="loginInfo.isAdmin" class="ms-3">쿠폰 신규 발급</h4>
    <div v-if="loginInfo.isAdmin" class="row mt-4">
      <div class="col-5">
        <label class="text-secondary">쿠폰기간</label>
        <div class="row row-cols-auto align-items-center">
          <div class="col me-0 pe-0">
            <input class="form-control" style="width: 80px" v-model="publish.time">
          </div>
          <label class="text-secondary ms-0 ps-1 me-3">일</label>
          <button v-for="time of [10, 30, 90]" :key="time"  class="btn btn-secondary mx-1" @click="publish.time = time">{{ time }}일</button>
        </div>
      </div>
      <div class="col pe-5">
        <label class="text-secondary">담당자 검색</label>
        <div class="input-group">
        <input class="form-control" v-model="searchManagers" />
        <button class="btn btn-secondary" @click="publishManagerClick">검색 <i class="bi bi-arrow-right-circle"></i></button>
        </div>
      </div>
      <div class="col">
        <label class="text-secondary">담당자 선택</label>
        <div class="row row-cols-auto align-items-center">
          <div class="dropdown d-inline-block">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="publishManager" data-bs-toggle="dropdown" aria-expanded="false">
              {{ publish.manager || '담당자 없음' }}
            </button>
            <ul class="dropdown-menu" aria-labelledby="publishManager">
              <li class="dropdown-item" @click="publish.manager = null">담당자 없음</li>
              <li v-for="manager in managersSearch" :key="manager.id" class="dropdown-item" @click="publish.manager = manager.id">{{manager.id}}<span v-if="manager.memo"> - {{manager.memo}}</span></li>
            </ul>
          </div>
          <span v-if="isManagersSearch" class="text ps-0">{{ managersSearch.length }}건으로 필터링됨</span>
        </div>
      </div>
    </div>
    <div v-if="loginInfo.isAdmin" class="row mt-4">
      <div class="col-2">
        <label class="text-secondary">유료 여부</label>
        <div class="row row-cols-auto align-items-center">
          <div class="col">
            <input class="form-check-input me-2" type="radio" id="publishBuyYes" v-model="publish.isBuy" value="Y" />
            <label class="form-check-label" for="publishBuyYes">유료 정식 사용권</label>
          </div>
          <div class="col">
            <input class="form-check-input me-2" type="radio" id="publishBuyNo" v-model="publish.isBuy" value="N"/>
            <label class="form-check-label" for="publishBuyNo">무료 쿠폰</label>
          </div>
        </div>
      </div>
      <div class="col-3">
        <label class="text-secondary">쿠폰 이름</label>
        <div class="row row-cols-auto align-items-center">
          <div class="col">
            <input class="form-check-input me-2" type="checkbox" v-model="publishAutoText" id="publishAutoText" />
            <label class="form-check-label" for="publishAutoText">자동</label>
          </div>
          <div class="col-8 pe-1">
            <input class="form-control" :disabled="publishAutoText" v-model="publish.text" />
          </div>
        </div>
      </div>
      <div class="col">
        <label class="text-secondary">관리자 메모</label>

        <input class="form-control" v-model="publish.adminMemo" />
      </div>
    </div>
    <div v-if="loginInfo.isAdmin" class="row mt-4">
      <div class="col-5">
        <label class="text-secondary">쿠폰 발급 갯수</label>
        <div class="row row-cols-auto align-items-center">
          <div class="col me-0 pe-0">
            <input class="form-control" style="width: 80px" v-model="publish.count" />
          </div>
          <label class="text-secondary ms-0 ps-1 me-3">개</label>
          <button v-for="count of [10, 20, 50]" :key="count" class="btn btn-secondary mx-1" @click="publish.count = count">{{ count }}개</button>
        </div>
      </div>
      <div class="col">
        <label class="text-secondary">담당자 메모</label>
        <input class="form-control" v-model="publish.managerMemo" />
      </div>
    </div>
    <div v-if="loginInfo.isAdmin" class="row text-center mt-4 mb-5">
      <div class="col">
        <button class="btn btn-primary" @click="publishClick">쿠폰 발급</button>
      </div>
    </div>
    <div class="modal fade" id="modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalLabel">쿠폰 삭제</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            선택한 {{ select.coupons.length }}개의 쿠폰을 삭제하시겠습니까?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">취소</button>
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="batchDelete">확인</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.blink {
  animation: blink-animation 0.3s steps(5, start) 6;
}
@keyframes blink-animation {
  to {
    opacity: 0.4;
  }
}
.marked {
  background-color: yellow;
}
</style>

<script>
import Datepicker from 'vue3-datepicker';
import { reactive, ref, watch } from 'vue';
import moment from 'moment';
import Menu from '@/components/Menu.vue';
import ko from 'date-fns/locale/ko';

export default {
  components: {
    Menu, Datepicker
  },
  props: {
    urlPage: String
  },
  setup(props) {
    const loginInfo = reactive({});
    const locale = ko;

    const msg = ref(null);
    const couponList = ref([]);
    const dateFormat = (dt) => moment(dt).format('YYYY년 M월 D일 A h:mm');
    const couponListPager = ref({ page: parseInt(props.urlPage, 10) });

    const msgCloseClick = () => {
      msg.value = "";
      markedCoupons.value = [];
    };

    const isManagersSearch = ref(false);
    let managers;
    const managersSearch = ref([]);
    const couponListRequest = async () => {
      try{
        let res = await backend({ action: 'couponList', page: couponListPager.value.page });
        if(await loginCheck(loginInfo, res)) return;
        couponList.value = res.couponList;
        couponListPager.value = res.couponListPager;
        couponListPager.value.btns = [];
        for(let page = 1; page<=res.couponListPager.pageMax; page++) couponListPager.value.btns.push(page);

        search.isSearch = false;
        if(!batch.manager) batch.manager = loginInfo.id;
        if(!publish.manager) publish.manager = loginInfo.id;
        
        managers = managersSearch.value = loginInfo.isAdmin ? res.managerList : [ loginInfo.id ];
        
        window.scrollTo(0, 0);

      }catch(e){
        console.log(e);
      }
    }
    couponListRequest();

    watch(couponList, (value) => value.forEach((coupon, idx) => {
        coupon.num = idx + couponListPager.value.startNum;
        coupon.timeFormat = String(parseInt(coupon.time / (1000 * 60 * 60 * 24), 10)) + '일';
        coupon.publishFormat = dateFormat(coupon.publish);
        if(coupon.used) coupon.usedFormat = dateFormat(coupon.used);
    }), { deep: true });

    const select = reactive({
      coupons: [],
      all: false
    });
    const selectAllChange = () => select.coupons = select.all ? couponList.value.map((coupon) => coupon.code) : []

    const search = reactive({
      show: false,
      coupon: {},
      time: {},
      publish: {},
      used: { state: 'all' },
      manager: {},
    });
    const searchShowClick = () => {
      search.show = !search.show;
      if(!search.show){
        search.coupon = {};
        search.time = {};
        search.publish = {};
        search.used = { state: 'all' };
        search.manager = {};
        couponListPager.value.page = parseInt(props.urlPage, 10);
        couponListRequest();
      }
    };
    const searchManagers = ref('');
    const searchManagerClick = () => {
      managersSearch.value = managers.filter((manager) => manager.id.indexOf(searchManagers.value) != -1 || (manager.memo && manager.memo.indexOf(searchManagers.value) != -1));
      if(managersSearch.value.length == 1){
        search.manager.select = managersSearch.value[0].id;
      }else if(search.manager.select && !managersSearch.value.find((manager) => manager.id == search.manager.select)){
        search.manager.select = null;
      }
      isManagersSearch.value = managersSearch.value.length != managers.length;
    };
    const searchRequest = async (page) => {
      let res = await backend({
        action: 'couponSearch',
        page: page,
        coupon: search.coupon,
        time: search.time,
        publish: search.publish,
        used: search.used,
        manager: search.manager
      });
      if(!res.isError){
        couponList.value = res.couponList;
        couponListPager.value = res.couponListPager;
        couponListPager.value.btns = [];
        search.isSearch = true;
        for(let page = 1; page<=res.couponListPager.pageMax; page++) couponListPager.value.btns.push(page);
      }
    };

    const markedCoupons = ref([]);

    const batch = reactive({});
    const requestProc = (res, newMsg, coupons) => {
      if(res.isError) return;

      if(coupons || res.codes) {
        markedCoupons.value = coupons || res.codes;
      }else{
        markedCoupons.value = [];
      }

      let m = res.all !== undefined && res.noUsed !== undefined && res.all != res.noUsed ? (res.noUsed == 0 ? '사용되지 않은 쿠폰만 삭제할 수 있습니다.' : '사용되지 않은 ' + String(res.noUsed) + '개의 쿠폰만 삭제했습니다.') : newMsg;

      if(m){
        search.isSearch = false;
        msg.value = null;
        setTimeout(() => msg.value = m, 0);
      } else if(search.isSearch) return searchRequest(couponListPager.value.page);

      couponListRequest();
    };
    const batchDelete = async () => requestProc(await backend({ action: 'couponBatchDelete', coupons: select.coupons }), '쿠폰을 삭제했습니다.', select.coupons);
    const batchTextRequest = async () => requestProc(await backend({ action: 'couponBatchText', coupons: select.coupons, text: batch.text }), '쿠폰 이름을 수정했습니다.', select.coupons);
    const batchAdminMemoRequest = async () => requestProc(await backend({ action: 'couponBatchAdminMemo', coupons: select.coupons, memo: batch.adminMemo }), '관리자 메모를 수정했습니다.', select.coupons);
    const batchManagerMemoRequest = async () => requestProc(await backend({ action: 'couponBatchManagerMemo', coupons: select.coupons, memo: batch.managerMemo }), '담당자 메모를 수정했습니다.', select.coupons);

    const publish = reactive({
      time: 30,
      count: 1,
      isBuy: 'Y',
      manager: null
    });
    const publishManagerClick = () => {
      managersSearch.value = managers.filter((manager) => manager.id.indexOf(searchManagers.value) != -1 || (manager.memo && manager.memo.indexOf(searchManagers.value) != -1));
      if(managersSearch.value.length == 1){
        publish.manager = managersSearch.value[0].id;
      }else if(search.manager.select && !managersSearch.value.find((manager) => manager.id == search.manager.select)){
        publish.manager = null;
      }
      isManagersSearch.value = searchManagers.value && managersSearch.value.length != 1;
    };

    const publishAutoText = ref(true);
    const publishAutoTextProc = () => publish.text = publish.time < 1 ? '테스트 쿠폰' : (String(publish.time) + '일 ' + (publish.isBuy == 'Y' ? '정식 사용권' : '무료 쿠폰'));
    watch(publish, () => {
      if(publishAutoText.value) publishAutoTextProc();
    }, { immediate: true });
    watch(publishAutoText, () => publishAutoTextProc());

    const publishClick = async () => requestProc(await backend({ action: 'couponPublish', publish: publish }), '쿠폰을 발급했습니다.');

    return {
      locale, loginInfo, msg, msgCloseClick,
      couponList, couponListPager,
      isManagersSearch, managersSearch,
      select, selectAllChange,
      search, searchShowClick, searchManagers, searchManagerClick, searchRequest,
      markedCoupons,
      batch, batchDelete, batchTextRequest, batchAdminMemoRequest, batchManagerMemoRequest,
      publish, publishManagerClick, publishAutoText, publishClick
    };
  }
}
</script>
